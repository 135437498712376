<template>
  <div class="cardBox" @click="goProduct">
    <v-row>
      <v-col :cols="2" :sm="2" class="d-flex align-center">
        <v-img src="../assets/image/beer.png" />
      </v-col>
      <v-col :cols="8" :sm="8">
        <v-row>
          <span class="header">{{ store.name_store }}</span>
        </v-row>
        <v-row>
          <span class="subHeader">{{ fullAddress }}</span>
        </v-row>
      </v-col>
      <v-col :cols="2" :sm="2" @click.stop="goEdit">
        <v-icon>edit</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'ListCard',
  methods: {
    ...mapMutations(['setStoreId', 'setStoreName', 'setStoreType']),
    goEdit() {
      this.$router.push(`/editshop?shopid=${this.store.store_id}`)
    },
    goProduct() {
      this.setStoreId(this.store.store_id)
      this.setStoreName(this.store.name_store)
      this.setStoreType(this.store?.shop_type)
      this.$router.push('/product')
    },
  },
  props: {
    store: Object,
  },
  computed: {
    fullAddress() {
      return `${this.store.address.address_1} ${this.store.address.subdistrict} ${this.store.address.district} ${this.store.address.province} ${this.store.address.postal_code}`
    },
  },
}
</script>

<style lang="scss" scoped>
.cardBox {
  background-color: #efefef;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .subHeader {
    color: #9da1a7;
    font-size: 20px;
    font-family: db_heaventregular;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
