<template>
  <v-content>
    <div class="box">
      <span class="alertText" v-if="shopList.length > 0"
        >กรุณาเลือกร้านค้าก่อนทำการสั่งซื้อสินค้า</span
      >
      <span class="alertText" v-else>กรุณาเพิ่มร้านค้าก่อนทำการสั่งซื้อสินค้า</span>
    </div>
    <v-container v-if="shopList.length > 0" class="mb-10">
      <div class="cardBox" v-for="(store, index) in shopList" :key="index">
        <ListCard :store="store"></ListCard>
      </div>
    </v-container>
    <Button icon="$vuetify.icons.plusShop" title="เพิ่มร้านค้า" :func="goRegisterShop"></Button>
  </v-content>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Button from '../components/Button'
import ListCard from '../components/ListCard'
export default {
  name: 'ListShop',
  components: {
    Button,
    ListCard,
  },
  methods: {
    ...mapMutations(['setShopList']),
    goRegisterShop() {
      this.$router.push('/shop')
    },
    async getListShop() {
      try {
        const res = await this.$axios({
          method: 'GET',
          url: '/api/store/list',
          params: {
            social_id: this.$store.state.tokenId,
          },
        })
        if (res.data.status === 'failed') {
          this.$modal.show({
            title: `ERROR: ${res.data.message}`,
            isOne: true,
          })
        }
        this.setShopList(res.data)
      } catch (e) {
        this.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
  },
  computed: {
    ...mapState(['shopList']),
  },
  mounted() {
    this.getListShop()
  },
}
</script>

<style lang="scss" scoped>
.box {
  background-color: black;
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
}
.alertText {
  color: white;
}
.cardBox {
  margin-bottom: 10px;
}
</style>
